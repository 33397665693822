import { useContext, useState, useRef, useEffect } from "react";
import { Context } from "../../utils/context";
import { useHttpClient } from "../../utils/http-hook";
import ModalContainer from "../shared/ModalContainer";
import Notification from "../shared/Notification";
import AppHeader from "../shared/AppHeader";
import Close from "../shared/Close";
import Select from "../shared/Select";
import Placeholder from "../../assets/placeholder-tile.jpg";
import AddContributorModal from "./components/AddContributorModal";
import SplitRevenueModal from "./components/SplitRevenueModal";
import CloseAccent from "../../assets/close-accent.svg";
import Loader from "../shared/Loader";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import SubscriptionCostInput from "./components/SubscriptionCostInput";
import "./PaymentsAnalytics.scss";

const TIMELINES = [
  {
    name: "All Time",
    value: "all",
  },
  {
    name: "Last 30 Days",
    value: "last_30_days",
  },
  {
    name: "Last 7 Days",
    value: "last_7_days",
  },
  {
    name: "Last 24 Hours",
    value: "last_24_hours",
  },
];

const PaymentsAnalytics = () => {
  const context = useContext(Context);
  const [timeline, setTimeline] = useState("last_30_days");
  const [total, setTotal] = useState(0);
  const [net, setNet] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [contributors, setContributors] = useState([]);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [subscriptionCost, setSubscriptionCost] = useState(0);
  const [openAddContributorModal, setOpenAddContributorModal] = useState(false);
  const [openSplitRevenueModal, setOpenSplitRevenueModal] = useState(false);
  const { sendRequest, isLoading, setIsLoading } = useHttpClient();

  const fetchSubscriptions = async () => {
    const requestUrl =
      context.userType === "admin"
        ? `${process.env.REACT_APP_API_URL}/subscriptions/all`
        : `${process.env.REACT_APP_API_URL}/subscriptions/all?userId=${context.userId}`;

    const responseData = await sendRequest(requestUrl, "GET", null, {
      Authorization: "Bearer " + context.token,
      "Content-Type": "application/json",
    });

    if (responseData) {
      if (responseData.logout) {
        context.logout();
        return;
      }

      setSubscriptions(responseData.subscriptions);
      setActiveSubscription(responseData.subscriptions[0]);
    }
  };

  const fetchTransactions = async (subscriptionId, exportData = false) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_API_URL}/transactions/${subscriptionId}?timeline=${timeline}&export=${exportData}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    if (responseData) {
      if (responseData.logout) {
        context.logout();
        return;
      }

      if (exportData) {
        exportToCSV(responseData.transactions);
      } else {
        setTotal(responseData.total);
        setNet(responseData.total * 0.8);
        setTransactions(responseData.transactions);
        setRecentTransactions(responseData.recentTransactions);
      }
    }
  };

  useEffect(() => {
    if (!activeSubscription) {
      fetchSubscriptions();
    } else {
      fetchTransactions(activeSubscription._id);
      setSubscriptionCost(activeSubscription.usdPrice);
      setContributors(activeSubscription.contributors);
    }
  }, [context.token, context.userType, activeSubscription, timeline]);

  const formatTooltipDate = (date) => {
    const d = new Date(date);

    if (timeline === "last_24_hours") {
      return d
        .toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .replace(",", "");
    }

    if (timeline === "all") {
      return d.toLocaleString("en-US", {
        month: "long",
        year: "numeric",
      });
    }

    return d.toLocaleDateString("en-US");
  };

  const exportToCSV = (transactions) => {
    const title = "HAPPY MONDAY LABS";
    const headers = ["Date", "Amount", "Subscriber"];
    const rows = transactions.map((transaction) => [
      new Date(transaction.date).toLocaleDateString("en-US"),
      transaction.usdAmount.toFixed(2),
      transaction.creator.email,
    ]);

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [title, headers.join(","), ...rows.map((row) => row.join(","))].join(
        "\n"
      );

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `payments_${timeline}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const addContributor = (contributor) => {
    setContributors((c) => [...c, contributor]);
    setSubscriptions((s) => {
      const updatedSubscriptions = s.map((subscription) => {
        if (subscription._id === activeSubscription._id) {
          return {
            ...subscription,
            contributors: subscription.contributors
              ? [...subscription.contributors, contributor]
              : [contributor],
          };
        }
        return subscription;
      });
      return updatedSubscriptions;
    });
    setActiveSubscription((s) => ({
      ...s,
      contributors: s.contributors
        ? [...s.contributors, contributor]
        : [contributor],
    }));
  };

  const removeContributor = async (id) => {
    setContributors((c) => c.filter((c) => c._id !== id));
    setSubscriptions((s) => {
      const updatedSubscriptions = s.map((subscription) => {
        if (subscription._id === activeSubscription._id) {
          return {
            ...subscription,
            contributors: subscription.contributors
              ? subscription.contributors.filter((c) => c._id !== id)
              : [],
          };
        }
        return subscription;
      });
      return updatedSubscriptions;
    });
    setActiveSubscription((s) => ({
      ...s,
      contributors: s.contributors
        ? s.contributors.filter((c) => c._id !== id)
        : [],
    }));

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/subscriptions/remove-contributor`,
        "POST",
        JSON.stringify({
          subscriptionId: activeSubscription._id,
          contributorId: id,
        }),
        {
          Authorization: "Bearer " + context.token,
          "Content-Type": "application/json",
        }
      );

      if (responseData) {
        if (responseData.logout) {
          context.logout();
          return;
        }
      }
    } catch (e) {}
  };

  const updateRevenueSplit = (contributors) => {
    setContributors(contributors);
    setSubscriptions((s) => {
      const updatedSubscriptions = s.map((subscription) => {
        if (subscription._id === activeSubscription._id) {
          return {
            ...subscription,
            contributors,
          };
        }
        return subscription;
      });
      return updatedSubscriptions;
    });
    setActiveSubscription((s) => ({
      ...s,
      contributors,
    }));
  };

  const getCreatorRevenueShare = () => {
    if (!activeSubscription || !activeSubscription.contributors) return 100;
    const contributorsShare = activeSubscription.contributors.reduce(
      (sum, s) => sum + s.revenueShare,
      0
    );
    return 100 - contributorsShare;
  };

  return (
    <ModalContainer>
      <Notification
        text={`CREDITS [${
          context.userType === "admin" || context.userType === "artist"
            ? "unlimited"
            : context.credits
        }]`}
      />
      <AppHeader text="Payments Manager" />
      <Close onClick={() => context.setTab(null)} />

      <div className="payments">
        <div className="payments__header">
          <Select
            data={TIMELINES}
            default={timeline}
            onChange={(value) => setTimeline(value)}
          />

          {subscriptions.length > 0 && (
            <Select
              data={subscriptions.map((s) => ({
                name: `${s.creator.name}'s Library`,
                value: s._id,
              }))}
              default={activeSubscription._id}
              onChange={(value) =>
                setActiveSubscription(
                  subscriptions.find((s) => s._id === value)
                )
              }
            />
          )}
        </div>

        <div className="payments__container">
          <div className="payments__col_1">
            <div className="payments__data_cells">
              <div className="payments__data_cell">
                <span>Total Revenue</span>
                <h3>${total.toFixed(2)}</h3>
              </div>

              <div className="payments__data_cell">
                <span>Net Revenue</span>
                <h3>${net.toFixed(2)}</h3>
              </div>
            </div>

            <div className="payments__chart_container">
              <ResponsiveContainer width="100%" height={400}>
                <AreaChart
                  data={transactions}
                  margin={{ top: 10, right: 10, left: 0, bottom: 0 }}
                >
                  <XAxis
                    dataKey="date"
                    stroke="#6b7280"
                    fontSize={12}
                    tickMargin={10}
                    tick={false} // Remove X-axis labels
                    axisLine={{ stroke: "#6b7280" }}
                    tickLine={false}
                    tickFormatter={formatTooltipDate}
                  />
                  <YAxis
                    stroke="#6b7280"
                    fontSize={12}
                    tickFormatter={(value) => `$${value}`}
                    domain={["auto", "auto"]}
                    axisLine={{ stroke: "#6b7280" }}
                    tickLine={false}
                    gridLines={false}
                  />
                  <CartesianGrid
                    vertical={false}
                    horizontal={true}
                    stroke="#6b7280"
                    opacity={0.2}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "#18181b",
                      border: "none",
                      borderRadius: "6px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      padding: "12px",
                    }}
                    formatter={(value) => [`$${value}`, "Revenue"]}
                    labelFormatter={formatTooltipDate}
                    labelStyle={{
                      color: "#94a3b8",
                      marginBottom: "4px",
                      fontSize: "12px",
                    }}
                    itemStyle={{
                      color: "#f8fafc",
                      fontSize: "14px",
                      padding: "0",
                    }}
                    cursor={{ stroke: "#6b7280" }} // Change tooltip vertical line color
                  />
                  <Area
                    type="monotone"
                    dataKey="usdAmount"
                    stroke="#1e40af"
                    strokeWidth={2}
                    fill="#0A132F"
                    dot={{
                      stroke: "#1e40af",
                      strokeWidth: 2,
                      r: 4,
                      fill: "#fff",
                    }}
                    activeDot={{
                      stroke: "#1e40af",
                      strokeWidth: 2,
                      r: 6,
                      fill: "#3D5ECB",
                    }}
                  />
                </AreaChart>
              </ResponsiveContainer>

              {isLoading && (
                <div className="payments__loader">
                  <Loader />
                </div>
              )}
            </div>

            {recentTransactions.length > 0 && (
              <div className="payments__list_container">
                <p>Recent payments</p>

                <div className="payments__list">
                  {recentTransactions.map((transaction) => (
                    <div className="payments__list_item">
                      <div>
                        <h4>{transaction.creator.name}</h4>
                        <p>
                          {new Date(transaction.date).toLocaleDateString(
                            "en-US"
                          )}
                        </p>
                      </div>
                      <span>${transaction.usdAmount.toFixed(2)}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div
              className="button"
              onClick={() => fetchTransactions(activeSubscription._id, true)}
            >
              Export Payments (CSV)
            </div>
          </div>

          <div className="payments__col_2">
            {activeSubscription && (
              <div className="payments__artist">
                <img
                  src={
                    activeSubscription.creator.img
                      ? activeSubscription.creator.img
                      : Placeholder
                  }
                  alt=""
                />
                <div>
                  <h3>{activeSubscription.creator.name}</h3>
                  <p>Artist</p>
                </div>
                <span>{getCreatorRevenueShare()}%</span>
              </div>
            )}
            <div className="payments__buttons">
              <div
                className="payments__button"
                onClick={() => setOpenAddContributorModal(true)}
              >
                Add Contributor
              </div>
              <div
                className="payments__button"
                onClick={() => setOpenSplitRevenueModal(true)}
              >
                Split Revenue
              </div>
            </div>

            {contributors && contributors.length > 0 && (
              <div className="payments__contributors">
                {contributors.map((contributor, i) => (
                  <div className="payments__contributor">
                    <p>{i + 1}</p>
                    <img
                      src={
                        contributor.user.img
                          ? contributor.user.img
                          : Placeholder
                      }
                      alt=""
                    />
                    <div>
                      <div className="payments__contributor_info">
                        <h3>{contributor.user.name}</h3>
                        <img
                          src={CloseAccent}
                          alt="Remove"
                          title="Remove Contributor"
                          onClick={() => removeContributor(contributor._id)}
                        />
                      </div>
                      <p>{contributor.title}</p>
                    </div>
                    <span>{contributor.revenueShare}%</span>
                  </div>
                ))}
              </div>
            )}

            {isLoading && (
              <div className="payments__loader">
                <Loader />
              </div>
            )}

            <div className="payments__sub">
              <h3>Subscription Cost (mo)</h3>
              <SubscriptionCostInput
                subscriptionCost={subscriptionCost}
                setSubscriptionCost={setSubscriptionCost}
                activeSubscription={activeSubscription}
              />
            </div>
          </div>
        </div>
      </div>

      {openAddContributorModal && (
        <AddContributorModal
          subscription={activeSubscription._id}
          closeModal={() => setOpenAddContributorModal(false)}
          addContributor={addContributor}
        />
      )}

      {openSplitRevenueModal && (
        <SplitRevenueModal
          subscription={activeSubscription._id}
          closeModal={() => setOpenSplitRevenueModal(false)}
          updateRevenueSplit={updateRevenueSplit}
          contributors={
            activeSubscription.contributors
              ? [
                  {
                    user: activeSubscription.creator,
                    title: "Artist",
                    revenueShare: getCreatorRevenueShare(),
                  },
                  ...activeSubscription.contributors,
                ]
              : [
                  {
                    user: activeSubscription.creator,
                    title: "Artist",
                    revenueShare: getCreatorRevenueShare(),
                  },
                ]
          }
        />
      )}
    </ModalContainer>
  );
};

export default PaymentsAnalytics;
