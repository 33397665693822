import { useState, useCallback, useEffect } from "react";

let logoutTimer;
export default function useAuth() {
  const [token, setToken] = useState();
  const [tokenExp, setTokenExp] = useState();
  const [userId, setUserId] = useState();
  const [userType, setUserType] = useState();
  const [email, setEmail] = useState();
  const [credits, setCredits] = useState();
  const [theme, setTheme] = useState();

  const login = useCallback(
    (userId, userType, token, email, credits, theme, expirationDate) => {
      setToken(token);
      setUserType(userType);
      setUserId(userId);
      setEmail(email);
      setCredits(credits);
      setTheme(theme);

      const tokenExpDate =
        expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 24);

      setTokenExp(tokenExpDate);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: userId,
          token: token,
          userType: userType,
          expiration: tokenExpDate.toISOString(),
          email,
          credits,
          theme,
        })
      );
    },
    []
  );

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setTokenExp(null);
    setUserType(null);
    setEmail(null);
    setCredits(null);
    setTheme(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (
      userData &&
      userData.token &&
      new Date(userData.expiration) > new Date()
    ) {
      login(
        userData.userId,
        userData.userType,
        userData.token,
        userData.email,
        userData.credits,
        userData.theme,
        new Date(userData.expiration)
      );
    } else {
      logout();
    }
  }, [login]);

  useEffect(() => {
    if (token && tokenExp) {
      const remainingTime = tokenExp.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExp]);

  return {
    token,
    login,
    logout,
    userId,
    userType,
    email,
    credits,
    theme,
    setTheme,
    setCredits,
  };
}
