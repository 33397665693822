import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { Context } from "../../../utils/context";
import { firebaseDelete } from "../../../utils/upload-hook";
import "./PlayerButtons.scss";
import { useHttpClient } from "../../../utils/http-hook";

const AIButtons = () => {
  const navigate = useNavigate();
  const context = useContext(Context);

  const deleteSong = () => {
    if (context.activeAISong.songPath) {
      firebaseDelete(context.activeAISong.songPath);
      context.setActiveAISong(null);
      context.setTab();
    }
  };

  const regenerateHandler = () => {
    if (
      context.credits > 0 ||
      context.userType === "admin" ||
      context.userType === "artist"
    ) {
      context.generateSong(context.activeAISong.originalSongId);
    } else {
      navigate("/subscribe?no_redirect=true");
    }
  };

  return (
    <div className="player_buttons">
      <div className="player_buttons__row">
        <div className="player_button" onClick={deleteSong}>
          <div className="player_button__num">01</div>
          <p>Delete</p>
        </div>

        <div className="player_button" onClick={regenerateHandler}>
          <div className="player_button__num">02</div>
          <p>Retry</p>
        </div>
      </div>

      <div className="player_buttons__row">
        <div className="player_button" onClick={context.slowDown}>
          <div className="player_button__num">03</div>
          <p>Slow down</p>
        </div>

        <div className="player_button" onClick={context.spedUp}>
          <div className="player_button__num">04</div>
          <p>Sped up</p>
        </div>
      </div>
    </div>
  );
};

export default AIButtons;
