import { useContext, useState, useEffect } from "react";
import { Context } from "../../../utils/context";
import AppHeader from "../../shared/AppHeader";
import Placeholder from "../../../assets/placeholder-tile.jpg";
import Close from "../../shared/Close";
import Input from "../../shared/Input";
import { useHttpClient } from "../../../utils/http-hook";
import Loader from "../../shared/Loader";
import Button from "../../shared/Button";
import "./PaymentModal.scss";

const AddContributorModal = (props) => {
  const [title, setTitle] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const { sendRequest, isLoading, setIsLoading } = useHttpClient();
  const context = useContext(Context);

  // Fetch users when search term changes or on component mount
  useEffect(() => {
    setPage(1);
    setMaxPage(1);
    setUsers([]);
  }, [search]);

  useEffect(() => {
    const fetchUsers = async () => {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/`,
        "POST",
        JSON.stringify({ search, page, subscriptionId: props.subscription }),
        {
          Authorization: `Bearer ${context.token}`,
          "Content-Type": "application/json",
        }
      );

      if (responseData) {
        if (responseData.logout) {
          context.logout();
          return;
        }
        setMaxPage(responseData.maxPage);
        setUsers((users) => [
          ...users,
          ...responseData.users.filter((u) => u.id !== context.userId),
        ]);
      }
    };

    fetchUsers();
  }, [sendRequest, page, search]);

  const handleScroll = (e) => {
    if (isLoading || page > maxPage) return;
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleSearch = (e) => {
    let search = e.target.value;

    setTimeout(() => {
      if (search !== e.target.value) return;
      setSearch(search);
    }, 300);
  };

  const saveContributor = async () => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_API_URL}/subscriptions/add-contributor`,
      "POST",
      JSON.stringify({
        title,
        contributor: selectedUser._id,
        subscriptionId: props.subscription,
      }),
      {
        Authorization: `Bearer ${context.token}`,
        "Content-Type": "application/json",
      }
    );

    if (responseData) {
      if (responseData.logout) {
        context.logout();
        return;
      }

      props.addContributor({
        ...responseData.contributor,
        user: {
          _id: selectedUser._id,
          name: selectedUser.name,
          email: selectedUser.email,
          img: selectedUser.img,
        },
      });
      props.closeModal();
    }
  };

  return (
    <div className="payments_modal">
      <div
        className="payments_modal__backdrop"
        onClick={props.closeModal}
      ></div>
      
      <div className="payments_modal__container">
        <AppHeader text="Add Contributor" />
        <Close onClick={props.closeModal} />

        <div className="payments_modal__input_group">
          <label>Title</label>
          <Input
            placeholder="Producer"
            type="text"
            onInput={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className="payments_modal__input_group">
          <label>User</label>
          <Input
            placeholder="Search"
            type="text"
            onInput={(e) => handleSearch(e)}
          />
        </div>

        <div className="payments_modal__users" onScroll={handleScroll}>
          {users &&
            users.map((u) => (
              <div
                className={`payments_modal__user ${
                  selectedUser && selectedUser._id === u._id ? "active" : ""
                }`}
                onClick={() => setSelectedUser(u)}
              >
                <img src={u.img ? u.img : Placeholder} alt="" />
                <div>
                  <h3>{u.name}</h3>
                  <p>{u.email}</p>
                </div>
              </div>
            ))}
          {isLoading && <Loader />}
        </div>

        <div className="payments_modal__buttons">
          <Button
            text="Save"
            onClick={saveContributor}
            disabled={!title || !selectedUser}
          />
        </div>
      </div>
    </div>
  );
};

export default AddContributorModal;
