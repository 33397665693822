import { useEffect, useState } from "react";
import Chevron from "../../assets/select.svg";
import "./Select.scss";

const Select = (props) => {
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState(
    props.data.find((d) => d.value === props.default)?.name || ""
  );

  function clickHandler(e) {
    setIsSelected(e.target.textContent);
    setIsActive(false);
    props.onChange(
      props.data.find((d) => d.name === e.target.textContent).value
    );
  }

  function toggleDropDownHandler() {
    setIsActive(!isActive);
  }

  useEffect(() => {
    setIsSelected(
      props.data.find((d) => d.value === props.default)?.name || ""
    );
  }, [props]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isActive && !event.target.closest(".select")) {
        setIsActive(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isActive]);

  return (
    <div className="select">
      <div className="select__header" onClick={toggleDropDownHandler}>
        <span>{selected}</span>
        <img src={Chevron} alt="" />
      </div>

      {isActive && (
        <div className="select__body">
          {props.data.map((d) => (
            <div
              key={d.value}
              className="select__item"
              onClick={clickHandler}
              data-value={d.value}
            >
              {d.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
