import { useContext, useState, useRef, useEffect } from "react";
import { Context } from "../../utils/context";
import { useHttpClient } from "../../utils/http-hook";
import ModalContainer from "../shared/ModalContainer";
import Notification from "../shared/Notification";
import AppHeader from "../shared/AppHeader";
import Close from "../shared/Close";
import Select from "../shared/Select";
import Loader from "../shared/Loader";
import Placeholder from "../../assets/placeholder-tile.jpg";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import "./Analytics.scss";

const TIMELINES = [
  {
    name: "All Time",
    value: "all",
  },
  {
    name: "Last 30 Days",
    value: "last_30_days",
  },
  {
    name: "Last 7 Days",
    value: "last_7_days",
  },
  {
    name: "Last 24 Hours",
    value: "last_24_hours",
  },
];

const Analytics = () => {
  const context = useContext(Context);
  const [timeline, setTimeline] = useState("last_30_days");
  const [plays, setPlays] = useState([]);
  const [totalPlays, setTotalPlays] = useState(0);
  const [totalSubscribers, setTotalSubscribers] = useState(0);
  const [subscriptionRevenue, setSubscriptionRevenue] = useState(0);
  const [creditsRevenue, setCreditsRevenue] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [topSongs, setTopSongs] = useState([]);
  const [topUsersByPlays, setTopUsersByPlays] = useState([]);
  const [topUsersByTransactions, setTopUsersByTransactions] = useState([]);
  const { sendRequest, isLoading, setIsLoading } = useHttpClient();

  useEffect(() => {
    const fetchSubscriptions = async () => {
      const requestUrl =
        context.userType === "admin"
          ? `${process.env.REACT_APP_API_URL}/subscriptions/all`
          : `${process.env.REACT_APP_API_URL}/subscriptions/all?userId=${context.userId}`;

      const responseData = await sendRequest(requestUrl, "GET", null, {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      });

      if (responseData) {
        if (responseData.logout) {
          context.logout();
          return;
        }

        setSubscriptions(responseData.subscriptions);
        setActiveSubscription(responseData.subscriptions[0]);
      }
    };

    const fetchAnalytics = async () => {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/get-artist-analytics?artistId=${activeSubscription.creator._id}&subscriptionId=${activeSubscription._id}&timeline=${timeline}`,
        "GET",
        null,
        {
          Authorization: "Bearer " + context.token,
          "Content-Type": "application/json",
        }
      );

      if (responseData) {
        if (responseData.logout) {
          context.logout();
          return;
        }
        setTotalPlays(responseData.totalPlays);
        setTotalSubscribers(responseData.totalSubscribers);
        setSubscriptionRevenue(responseData.subscriptionRevenue);
        setCreditsRevenue(responseData.creditsRevenue);
        setPlays(responseData.playsData);
        setTopSongs(responseData.topSongs);
        setTopUsersByPlays(responseData.topUsersByPlays);
        setTopUsersByTransactions(responseData.topUsersByTransactions);
      }
    };

    if (!activeSubscription) {
      fetchSubscriptions();
    } else {
      fetchAnalytics();
    }
  }, [context.token, context.userType, activeSubscription, timeline]);

  const formatTooltipDate = (date) => {
    const d = new Date(date);

    if (timeline === "last_24_hours") {
      return d
        .toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .replace(",", "");
    }

    if (timeline === "all") {
      return d.toLocaleString("en-US", {
        month: "long",
        year: "numeric",
      });
    }

    return d.toLocaleDateString("en-US");
  };

  const exportAnalytics = async () => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_API_URL}/users/get-artist-analytics-export?artistId=${activeSubscription.creator._id}&timeline=${timeline}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    if (responseData) {
      if (responseData.logout) {
        context.logout();
        return;
      }
      exportToCSV(responseData.topSongs);
    }
  };

  const exportToCSV = (songs) => {
    const title = "HAPPY MONDAY LABS";
    const headers = ["Artist", "Song", "Plays"];
    const rows = songs.map((song) => [song.artist, song.name, song.playCount]);

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [title, headers.join(","), ...rows.map((row) => row.join(","))].join(
        "\n"
      );

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `plays_${timeline}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <ModalContainer>
      <Notification
        text={`CREDITS [${
          context.userType === "admin" || context.userType === "artist"
            ? "unlimited"
            : context.credits
        }]`}
      />
      <AppHeader text="Analytics" />
      <Close onClick={() => context.setTab(null)} />

      <div className="analytics">
        <div className="analytics__header">
          <Select
            data={TIMELINES}
            default={timeline}
            onChange={(value) => setTimeline(value)}
          />

          {subscriptions.length > 0 && (
            <Select
              data={subscriptions.map((s) => ({
                name: s.creator.name,
                value: s._id,
              }))}
              default={activeSubscription._id}
              onChange={(value) =>
                setActiveSubscription(
                  subscriptions.find((s) => s._id === value)
                )
              }
            />
          )}
        </div>
        <div className="analytics__stats">
          <div className="analytics__stat">
            <h3>{totalPlays}</h3>
            <p>Total Plays</p>
          </div>
          <div className="analytics__stat">
            <h3>{totalSubscribers}</h3>
            <p>Total Subscribers</p>
          </div>
          <div className="analytics__stat">
            <h3>${subscriptionRevenue}</h3>
            <p>Subscription Revenue</p>
          </div>
          <div className="analytics__stat">
            <h3>${creditsRevenue}</h3>
            <p>Credits Revenue</p>
          </div>
        </div>
        <div className="analytics__chart_container">
          <ResponsiveContainer width="100%" height={400}>
            <AreaChart
              data={plays}
              margin={{ top: 10, right: 10, left: 0, bottom: 0 }}
            >
              <XAxis
                dataKey="date"
                stroke="#6b7280"
                fontSize={12}
                tickMargin={10}
                tick={false} // Remove X-axis labels
                axisLine={{ stroke: "#6b7280" }}
                tickLine={false}
                tickFormatter={formatTooltipDate}
              />
              <YAxis
                stroke="#6b7280"
                fontSize={12}
                domain={["auto", "auto"]}
                axisLine={{ stroke: "#6b7280" }}
                tickLine={false}
                gridLines={false}
              />
              <CartesianGrid
                vertical={false}
                horizontal={true}
                stroke="#6b7280"
                opacity={0.2}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: "#18181b",
                  border: "none",
                  borderRadius: "6px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  padding: "12px",
                }}
                formatter={(value) => [`${value}`, "Plays"]}
                labelFormatter={formatTooltipDate}
                labelStyle={{
                  color: "#94a3b8",
                  marginBottom: "4px",
                  fontSize: "12px",
                }}
                itemStyle={{
                  color: "#f8fafc",
                  fontSize: "14px",
                  padding: "0",
                }}
                cursor={{ stroke: "#6b7280" }} // Change tooltip vertical line color
              />
              <Area
                type="monotone"
                dataKey="count"
                stroke="#1e40af"
                strokeWidth={2}
                fill="#0A132F"
                dot={{
                  stroke: "#1e40af",
                  strokeWidth: 2,
                  r: 4,
                  fill: "#fff",
                }}
                activeDot={{
                  stroke: "#1e40af",
                  strokeWidth: 2,
                  r: 6,
                  fill: "#3D5ECB",
                }}
              />
            </AreaChart>
          </ResponsiveContainer>

          {isLoading && (
            <div className="analytics__loader">
              <Loader />
            </div>
          )}
        </div>
        <div className="analytics__lists">
          <div className="analytics__list">
            <h4>Top songs [plays]</h4>
            {topSongs.map((t, i) => (
              <div className="analytics__play">
                <p>{i + 1})</p>
                <p>{t.playCount}</p>
                <p>{t.name}</p>
              </div>
            ))}
          </div>

          <div className="analytics__list">
            <h4>Top fans [revenue]</h4>
            {topUsersByTransactions.map((f) => (
              <div className="analytics__user">
                <img src={f.img ? f.img : Placeholder} alt="" />
                <div>
                  <p>{f.name}</p>
                  <p>{f.email}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="analytics__list">
            <h4>Top fans [activity]</h4>
            {topUsersByPlays.map((f) => (
              <div className="analytics__user">
                <img src={f.img ? f.img : Placeholder} alt="" />
                <div>
                  <p>{f.name}</p>
                  <p>{f.email}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="button" onClick={exportAnalytics}>
          Export Data (CSV)
        </div>
      </div>
    </ModalContainer>
  );
};

export default Analytics;
