import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../utils/context";
import { useHttpClient } from "../../../utils/http-hook";
import AppHeader from "../../shared/AppHeader";
import Button from "../../shared/Button";
import Loader from "../../shared/Loader";
import "./CancelSubscriptionModal.scss";

const CancelSubscriptionModal = (props) => {
  const navigate = useNavigate();
  const context = useContext(Context);
  const { sendRequest, isLoading } = useHttpClient();
  const [subscriptions, setSubscriptions] = useState([]);

  const cancelSubscriptions = async () => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_API_URL}/subscriptions/cancel/${props.id}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    if (responseData) {
      if (responseData.logout) {
        context.logout();
        return;
      }
      if (subscriptions.length === 1) {
        navigate("/");
        return;
      }

      setSubscriptions((sub) =>
        sub.map((s) => {
          if (s.subscriptionType._id === props.id) {
            return { ...s, cancelled: true };
          }
          return s;
        })
      );
      props.closeModal();
    }
  };

  return (
    <div className="subscription_modal">
      <div
        className="subscription_modal__backdrop"
        onClick={props.closeModal}
      ></div>

      <div className="subscription_modal__container">
        <AppHeader text="Are you sure you want to cancel?" />

        {isLoading ? (
          <Loader />
        ) : (
          <div className="subscription_modal__buttons">
            <Button text="Yes" onClick={cancelSubscriptions} />
            <Button text="No" onClick={props.closeModal} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CancelSubscriptionModal;
