import { useContext } from "react";
import { Context } from "../../utils/context";
import AppHeader from "../shared/AppHeader";
import SongsList from "../songs/components/SongsList";
import Notification from "../shared/Notification";
import Loader from "../shared/Loader";

const GenerateTab = () => {
  const context = useContext(Context);
  const AIEnabledSongs = context.songs
    ? context.songs.filter((s) => s.AIEnabled)
    : [];
  const remixes = context.songs
    ? context.songs.filter((s) => s.creator === context.userId && s.AIGenerated)
    : [];

  return (
    <div className="col col__1">
      <Notification
        text={`CREDITS [${
          context.userType === "admin" || context.userType === "artist"
            ? "unlimited"
            : context.credits
        }]`}
      />

      {AIEnabledSongs.length && (
        <>
          <AppHeader text="MPFIVE GENERATOR" />
          <SongsList songs={AIEnabledSongs} />
        </>
      )}

      {remixes.length !== 0 && (
        <>
          <AppHeader text="MY MPFIVE’S" />
          <SongsList songs={remixes} />
        </>
      )}

      {!AIEnabledSongs || (!AIEnabledSongs.length && <Loader />)}
    </div>
  );
};

export default GenerateTab;
