import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import ContentContainer from "../shared/ContentContainer";
import Notification from "../shared/Notification";
import { useHttpClient } from "../../utils/http-hook";
import SubscribeBody from "./components/SubscribeBody";
import SelectArtist from "./components/SelectArtist";
import "./Subscribe.scss";

const Subscribe = () => {
  const navigate = useNavigate();
  const context = useContext(Context);
  const { sendRequest, isLoading } = useHttpClient();
  const [artists, setArtists] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (!userData) {
      navigate("/login");
    }
  }, [context.token]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const activeSubscriptions = context.user.subscribedTo
      ? context.user.subscribedTo.filter(
          (subscription) => new Date(subscription.activeUntil) > new Date()
        )
      : [];

    if (
      (context.userType !== "user" || activeSubscriptions.length > 0) &&
      !urlParams.get("nr")
    ) {
      navigate("/player");
    }
  }, [context.user, context.userType]);

  useEffect(() => {
    const fetchArtists = async () => {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/get-artists`,
        "GET",
        null,
        {
          Authorization: "Bearer " + context.token,
          "Content-Type": "application/json",
        }
      );

      if (responseData) {
        if (responseData.logout) {
          context.logout();
          return;
        }
        setArtists(responseData.artists);
      }
    };

    if (context.token) fetchArtists();
  }, [context.token]);

  return (
    <ContentContainer>
      <Notification text={selectedArtist ? "PICK A PLAN" : "PICK AN ARTIST"} />
      <div className="subscribe">
        {selectedArtist ? (
          <SubscribeBody
            selectedArtist={selectedArtist}
            setSelectedArtist={setSelectedArtist}
            isLoading={isLoading}
          />
        ) : (
          <SelectArtist
            artists={artists}
            setSelectedArtist={setSelectedArtist}
            isLoading={isLoading}
          />
        )}
      </div>
    </ContentContainer>
  );
};

export default Subscribe;
