import { useContext } from "react";
import { Context } from "../../../utils/context";
import { useHttpClient } from "../../../utils/http-hook";
import Select from "../../shared/Select";

const PRICE_OPTIONS = [
  { name: "$1", value: 1 },
  { name: "$2", value: 2 },
  { name: "$3", value: 3 },
  { name: "$4", value: 4 },
  { name: "$5", value: 5 },
  { name: "$6", value: 6 },
  { name: "$7", value: 7 },
  { name: "$8", value: 8 },
  { name: "$9", value: 9 },
  { name: "$10", value: 10 },
  { name: "$11", value: 11 },
  { name: "$12", value: 12 },
  { name: "$13", value: 13 },
  { name: "$14", value: 14 },
  { name: "$15", value: 15 },
  { name: "$16", value: 16 },
  { name: "$17", value: 17 },
  { name: "$18", value: 18 },
  { name: "$19", value: 19 },
  { name: "$20", value: 20 },
  { name: "$50", value: 50 },
  { name: "$100", value: 100 },
];

const SubscriptionCostInput = ({
  subscriptionCost,
  setSubscriptionCost,
  activeSubscription,
}) => {
  const context = useContext(Context);
  const { sendRequest, isLoading, setIsLoading } = useHttpClient();
  const updateSubscriptionCost = async (price) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/subscriptions/update-subscription-price`,
        "POST",
        JSON.stringify({
          subscriptionId: activeSubscription._id,
          newPrice: price,
        }),
        {
          Authorization: "Bearer " + context.token,
          "Content-Type": "application/json",
        }
      );

      if (responseData) {
        if (responseData.logout) {
          context.logout();
          return;
        }
      }
    } catch (e) {}
  };

  return (
    <div>
      <Select
        data={PRICE_OPTIONS.map((s) => ({
          name: s.name,
          value: s.value,
        }))}
        default={subscriptionCost}
        onChange={(value) => {
          setSubscriptionCost(value);
          updateSubscriptionCost(value);
        }}
      />
    </div>
  );
};

export default SubscriptionCostInput;
