import AppHeader from "../../shared/AppHeader";
import Close from "../../shared/Close";

const WithdrawalSuccess = (props) => {
  return (
    <div className="payments_modal">
      <div
        className="payments_modal__backdrop"
        onClick={props.closeModal}
      ></div>
      <div className="payments_modal__container">
        <AppHeader text="Your funds have been sent" />
        <Close onClick={props.closeModal} />

        <p>
          Your funds have been sent to your Stripe account. You can check the
          status of your withdrawal in your Stripe dashboard.
        </p>
      </div>
    </div>
  );
};

export default WithdrawalSuccess;
