import Preview from "../../../assets/placeholder-tile.jpg";

const SubscribeArtist = (props) => {
  return (
    <div
      className={
        props.active
          ? "subscribe_tile subscribe_tile--active"
          : "subscribe_tile"
      }
      onClick={props.onClick}
    >
      <img src={props.img ? props.img : Preview} alt="" />
      <div>
        <h3>{props.name}</h3>
        <p>{props.uploadedSongsCount} SONGS ON HARD DRIVE</p>
      </div>
    </div>
  );
};

export default SubscribeArtist;
