import { useContext, useEffect, useState } from "react";
import { Context } from "../../utils/context";
import { useHttpClient } from "../../utils/http-hook";
import ModalContainer from "../shared/ModalContainer";
import Notification from "../shared/Notification";
import AppHeader from "../shared/AppHeader";
import Close from "../shared/Close";
import WithdrawalSuccess from "./components/WithdrawalSuccess";
import "./Withdrawal.scss";
import Loader from "../shared/Loader";
const Withdawal = () => {
  const context = useContext(Context);
  const [balance, setBalance] = useState(0);
  const [stripeId, setStripeId] = useState(null);
  const [withdrawalSuccess, setWithdrawalSuccess] = useState();
  const { sendRequest, isLoading, setIsLoading } = useHttpClient();

  const fetchBalanceDetails = async () => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_API_URL}/payment/balance`,
      "GET",
      null,
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    if (responseData) {
      setBalance(responseData.balance);
      setStripeId(responseData.stripePartnerId);
    }
  };
  const fetchStripeLink = async () => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_API_URL}/payment/connect-stripe`,
      "GET",
      null,
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    if (responseData) {
      if (responseData.logout) {
        context.logout();
        return;
      }

      if (responseData?.url) {
        window.location.href = responseData.url;
      }
    }
  };
  const removeStripe = async () => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_API_URL}/payment/remove-stripe`,
      "GET",
      null,
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    if (responseData) {
      if (responseData.logout) {
        context.logout();
        return;
      }

      if (responseData) {
        setStripeId(null);
      }
    }
  };

  const withdraw = async () => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_API_URL}/payment/withdraw`,
      "GET",
      null,
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    if (responseData) {
      if (responseData.logout) {
        context.logout();
        return;
      }

      setWithdrawalSuccess(true);
    }
  };

  useEffect(() => {
    fetchBalanceDetails();
  }, []);

  return (
    <ModalContainer>
      <Notification
        text={`CREDITS [${
          context.userType === "admin" || context.userType === "artist"
            ? "unlimited"
            : context.credits
        }]`}
      />
      <AppHeader text="Payments Manager" />
      <Close onClick={() => context.setTab(null)} />

      <div className="withdrawal">
        <div className="withdrawal__balance">
          <h2>Balance</h2>
          <div className="withdrawal__balance_amount">
            ${balance.toFixed(2)}
          </div>
        </div>

        <div className="withdrawal__buttons">
          {!stripeId && (
            <div className="withdrawal__btn" onClick={fetchStripeLink}>
              Connect Stripe
            </div>
          )}

          {stripeId && (
            <div className="withdrawal__btn" onClick={withdraw}>
              Withdraw
            </div>
          )}

          {stripeId && (
            <div
              className="withdrawal__btn withdrawal__btn--remove"
              onClick={removeStripe}
            >
              Remove Stripe
            </div>
          )}
        </div>
      </div>
      {isLoading && <Loader />}

      {withdrawalSuccess && (
        <WithdrawalSuccess closeModal={() => setWithdrawalSuccess(false)} />
      )}
    </ModalContainer>
  );
};

export default Withdawal;
