import { useContext, useState, useEffect } from "react";
import ButtonLink from "../../shared/ButtonLink";
import SubscribeTile from "./SubscribeTile";
import { Context } from "../../../utils/context";
import { useHttpClient } from "../../../utils/http-hook";
import AppHeader from "../../shared/AppHeader";
import CancelSubscription from "../../account-settings/components/CancelSubscription";
import Loader from "../../shared/Loader";
import "./SubscribeBody.scss";

const CREDITS = [
  {
    link: "https://buy.stripe.com/9AQg0R9hF5bx424004",
    price: "$5",
    features: [
      "REQUIRES PLAN",
      "[1] MPFIVE CREDIT",
      "ONE-TIME FEE",
      "PURCHASE AS NEEDED",
    ],
  },
  {
    link: "https://buy.stripe.com/dR63e53Xl33p5689AF",
    price: "$50",
    features: [
      "REQUIRES PLAN",
      "[25] MPFIVE CREDITS",
      "ONE-TIME FEE",
      "PURCHASE AS NEEDED",
    ],
  },
];

const SubscribeBody = ({ selectedArtist, setSelectedArtist }) => {
  const context = useContext(Context);
  const { sendRequest, isLoading } = useHttpClient();
  const [plans, setPlans] = useState([]);
  const [activeLink, setActiveLink] = useState("");
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  useEffect(() => {
    const fetchPlans = async () => {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/subscriptions/get-subscription-by-creator/${selectedArtist._id}`,
        "GET",
        null,
        {
          Authorization: "Bearer " + context.token,
          "Content-Type": "application/json",
        }
      );

      if (responseData && responseData.subscriptions)
        setPlans(
          responseData.subscriptions.map((s) => {
            return {
              price: `$${s.usdPrice}/MO`,
              link: s.paymentLink,
              features: [],
            };
          })
        );
    };

    fetchPlans();
  }, []);

  return (
    <div className="subscribe_body">
      <div className="subscribe_body__tiles">
        <div className="subsribe_body__row">
          <AppHeader text="Choose a plan" />
          {isLoading && <Loader />}
          {plans.map((p) => (
            <SubscribeTile
              {...p}
              features={[]}
              onClick={() => setActiveLink(p.link)}
              active={activeLink === p.link}
            />
          ))}
        </div>

        <div className="subsribe_body__row">
          <AppHeader text="Remix Credits" />
          {CREDITS.map((p) => (
            <SubscribeTile
              {...p}
              onClick={() => setActiveLink(p.link)}
              active={activeLink === p.link}
            />
          ))}
        </div>
      </div>

      <div className="subscribe_body__buttons">
        <ButtonLink
          text="Continue"
          to={`${activeLink}?prefilled_email=${context.email}`}
          disabled={!activeLink}
        />
        <button onClick={() => setSelectedArtist(null)} className="link">
          Back
        </button>
        <button className="link" onClick={() => context.logout()}>
          Log out
        </button>
      </div>
      {context.userType !== "admin" &&
        context.userType !== "artist" &&
        context.user.subscriptionCanceled && (
          <p className="cancel_subscription_par">
            Your subscription is canceled
          </p>
        )}

      {cancelModalOpen && (
        <CancelSubscription
          clear={() => setCancelModalOpen(false)}
          cancel={() =>
            context.setUser((u) => {
              return { ...u, subscriptionCanceled: true };
            })
          }
        />
      )}
    </div>
  );
};

export default SubscribeBody;
