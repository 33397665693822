import "./ModalContainer.scss";

const ModalContainer = (props) => {
  return (
    <div onScroll={props.onScroll} className="modal_container">
      {props.children}
    </div>
  );
};

export default ModalContainer;
