import { Context } from "./context";
import { useState, useCallback, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

export const useHttpClient = () => {
  const context = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(
    async (url, method = "GET", body = null, headers = {}) => {
      setIsLoading(true);
      const httpAbortController = new AbortController();
      activeHttpRequests.current.push(httpAbortController);

      try {
        const response = await fetch(url, {
          method,
          body,
          headers,
          signal: httpAbortController.signal,
        });

        const responseData = await response.json();

        activeHttpRequests.current = activeHttpRequests.current.filter(
          (ctrl) => ctrl !== httpAbortController
        );

        if (!response.ok) {
          setIsLoading(false);
          context.setError(responseData.message || "Request failed!");
          setTimeout(() => {
            context.setError("");
          }, 10000);
          return;
        }

        setIsLoading(false);
        return responseData;
      } catch (err) {
        if (err.name !== "AbortError") {
          context.setError(err.message);
          setTimeout(() => {
            context.setError("");
          }, 10000);

          setIsLoading(false);
        }
      }
    },
    [context]
  );

  useEffect(() => {
    return () => {
      //eslint-disable-next-line react-hooks/exhaustive-deps
      activeHttpRequests.current.forEach((ctrl) => ctrl.abort());
    };
  }, []);

  return { isLoading, sendRequest, setIsLoading };
};
