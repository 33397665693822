import { useContext, useState, useEffect } from "react";
import ButtonLink from "../../shared/ButtonLink";
import SubscribeArtist from "./SubscribeArtist";
import { Context } from "../../../utils/context";
import AppHeader from "../../shared/AppHeader";
import Loader from "../../shared/Loader";
import "./SubscribeBody.scss";

const SelectArtist = (props) => {
  const context = useContext(Context);
  const { artists, setSelectedArtist } = props;
  const [activeArtist, setActiveArtist] = useState();

  const activeSubscriptions = context.user.subscribedTo
    ? context.user.subscribedTo.filter(
        (subscription) => new Date(subscription.activeUntil) > new Date()
      )
    : [];

  return (
    <div className="subscribe_body">
      <div>
        <AppHeader text="Choose artist" />
        <div className="subscribe_body__grid">
          {artists.map((a) => (
            <SubscribeArtist
              {...a}
              onClick={() => setActiveArtist(a)}
              active={activeArtist === a}
            />
          ))}
        </div>
      </div>

      {props.isLoading && <Loader />}

      <div className="subscribe_body__buttons">
        <button
          className="link"
          onClick={() => setSelectedArtist(activeArtist)}
          disabled={!activeArtist}
        >
          Continue
        </button>

        <ButtonLink text="Back" to="/" />
        {(!activeSubscriptions || activeSubscriptions.length === 0) && (
          <button className="link" onClick={() => context.logout()}>
            Log out
          </button>
        )}
      </div>
    </div>
  );
};

export default SelectArtist;
