import { useContext, useEffect, useRef } from "react";
import { Context } from "../../../utils/context";
import AppHeader from "../../shared/AppHeader";
import Notification from "../../shared/Notification";
import EnergyLevels from "../../shared/EnergyLevels";
import Placeholder from "../../../assets/placeholder-tile.jpg";
import Close from "../../shared/Close";
import Button from "../../shared/Button";
import MultiSelect from "../../shared/MultiSelect";
import "./RemixInfo.scss";

const GenerateAdminSettings = () => {
  const context = useContext(Context);
  const song = context.songs
    ? context.songs.find((s) => s._id === context.activeSong)
    : null;

  return (
    <div className="col col__1">
      <Notification
        text={`CREDITS [${
          context.userType === "admin" || context.userType === "artist"
            ? "unlimited"
            : context.credits
        }]`}
      />
      <AppHeader text="Generate" />
      <Close onClick={() => context.setTab()} />

      <div className="remix_info">
        <div className="remix_info__details">
          <img src={song.imgURL || Placeholder} alt="" />
          <div>
            <h3>{song.name}</h3>
            <p>{song.artist}</p>
            <p>MP5PLAYER.NET</p>
          </div>
        </div>

        <div className="remix_info__inputs">
          <MultiSelect
            placeholder="Moods"
            values={context.customMoods}
            setValues={(moods) => context.setCustomMoods(moods)}
            data={[
              "Angry",
              "Busy & Frantic",
              "Dark",
              "Dreamy",
              "Elegant",
              "Epic",
              "Euphoric",
              "Fear",
              "Funny & Weird",
              "Glamorous",
              "Happy",
              "Heavy & Ponderous",
              "Hopeful",
              "Laid Back",
              "Mysterious",
              "Peaceful",
              "Restless",
              "Romantic",
              "Running",
              "Sad",
              "Scary",
              "Sentimental",
              "Sexy",
              "Smooth",
              "Suspense",
            ]}
          />

          <MultiSelect
            placeholder="Genres"
            values={context.customGenre}
            setValues={(genres) => context.setCustomGenre(genres)}
            data={[
              "Acoustic",
              "Hip Hop",
              "Beats",
              "Funk",
              "Pop",
              "Drum n Bass",
              "Trap",
              "Tokyo night pop",
              "Rock",
              "Latin",
              "House",
              "Tropical House",
              "Ambient",
              "Orchestra",
              "Electro & Dance",
              "Electronica",
              "Techno & Trance",
              "Jersey Club",
              "Drill",
              "R&B",
              "Lofi Hip Hop",
              "World",
              "Afrobeats",
              "Christmas",
              "Sexy Drill",
            ]}
          />

          <MultiSelect
            placeholder="Mute Stems"
            values={context.customMuteStems}
            setValues={(muteStems) => context.setCustomMuteStems(muteStems)}
            data={["bc", "bs", "dr", "me", "fe", "ff"]}
          />

          <MultiSelect
            placeholder="Tempo"
            values={context.customTempo}
            setValues={(tempo) => context.setCustomTempo(tempo)}
            data={["low", "normal", "high"]}
          />

          <EnergyLevels />
          <Button
            text="Generate"
            onClick={() =>
              context.generateSong(context.activeSong, context.mode)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default GenerateAdminSettings;
