import { useContext, useState, useEffect } from "react";
import { Context } from "../../../utils/context";
import AppHeader from "../../shared/AppHeader";
import Placeholder from "../../../assets/placeholder-tile.jpg";
import Close from "../../shared/Close";
import Input from "../../shared/Input";
import { useHttpClient } from "../../../utils/http-hook";
import Loader from "../../shared/Loader";
import Button from "../../shared/Button";
import "./PaymentModal.scss";

const SplitRevenueModal = (props) => {
  const { sendRequest, isLoading, setIsLoading } = useHttpClient();
  const context = useContext(Context);

  const [contributors, setContributors] = useState(props.contributors);

  const saveRevenueSplit = async () => {
    const filteredContributors = contributors.filter(
      (c) => c.title !== "Artist"
    );

    const responseData = await sendRequest(
      `${process.env.REACT_APP_API_URL}/subscriptions/update-revenue-split`,
      "POST",
      JSON.stringify({
        subscriptionId: props.subscription,
        contributors: filteredContributors,
      }),
      {
        Authorization: `Bearer ${context.token}`,
        "Content-Type": "application/json",
      }
    );

    if (responseData) {
      if (responseData.logout) {
        context.logout();
        return;
      }

      props.updateRevenueSplit(filteredContributors);
      props.closeModal();
    }
  };

  return (
    <div className="payments_modal">
      <div
        className="payments_modal__backdrop"
        onClick={props.closeModal}
      ></div>
      <div className="payments_modal__container">
        <AppHeader text="Split Revenue" />
        <Close onClick={props.closeModal} />

        <div className="payments_modal__users">
          {contributors &&
            contributors.map((u) => (
              <div className="payments_modal__user payments_modal__user--contributor">
                <img src={u.user.img ? u.user.img : Placeholder} alt="" />
                <div>
                  <h3>{u.user.name}</h3>
                  <p>{u.title}</p>
                </div>

                <div className="payments_modal__revenue_share">
                  <Input
                    type="string"
                    placeholder="0.00"
                    value={u.revenueShare}
                    onInput={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value)) {
                        setContributors((c) =>
                          c.map((cu) => {
                            if (cu.user._id === u.user._id) {
                              cu.revenueShare = +value;
                            }
                            return cu;
                          })
                        );
                      }
                    }}
                  />
                  %
                </div>
              </div>
            ))}
          {isLoading && <Loader />}
        </div>

        {contributors.reduce((acc, c) => acc + c.revenueShare, 0) !== 100 && (
          <p className="payments_modal__error">
            The sum of all revenue shares must be 100%
          </p>
        )}

        <div className="payments_modal__buttons">
          <Button
            text="Save"
            onClick={saveRevenueSplit}
            disabled={
              contributors.reduce((acc, c) => acc + c.revenueShare, 0) !== 100
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SplitRevenueModal;
