import AppHeader from "../../shared/AppHeader";
import Close from "../../shared/Close";
import Input from "../../shared/Input";
import "./PostModal.scss";

function convertToTikTokEmbedLink(link) {
  const regex = /https?:\/\/(?:www\.)?tiktok\.com\/@[^\/]+\/video\/(\d+)/;
  const match = link.match(regex);
  if (match && match[1]) {
    const videoId = match[1];
    const embedLink = `https://www.tiktok.com/embed/${videoId}`;
    return embedLink;
  } else {
    return null;
  }
}

const TikTokModal = (props) => {
  const addLink = (link) => {
    let formattedLink = convertToTikTokEmbedLink(link);

    if (formattedLink) {
      props.setTikTok(formattedLink);
    }
  };

  return (
    <div className="post_modal">
      <div className="post_modal__backdrop" onClick={props.closeModal}></div>
      <div className="post_modal__container">
        <Close onClick={props.closeModal} />
        <AppHeader text="Add A TikTok Video" />

        <Input
          placeholder="Link"
          value={props.tiktok}
          type="text"
          onInput={(e) => addLink(e.target.value)}
          required
        />

        {props.tiktok && (
          <div className="post_modal__tiktok_embed">
            <iframe
              src={props.tiktok}
              title="TikTok video player"
              allow="encrypted-media;"
              frameborder="0"
              scrolling="no"
              allowfullscreen
            ></iframe>
          </div>
        )}

        {props.tiktok && (
          <div className="post_modal__buttons">
            <div className="button" onClick={() => props.setTikTok("")}>
              Clear
            </div>

            <div className="button" onClick={props.closeModal}>
              Continue
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TikTokModal;
