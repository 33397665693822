import { useContext, useState, useEffect } from "react";
import { firebaseDelete, firebaseUpload } from "../../../utils/upload-hook";
import { Context } from "../../../utils/context";
import { useHttpClient } from "../../../utils/http-hook";
import ModalContainer from "../../shared/ModalContainer";
import Notification from "../../shared/Notification";
import AppHeader from "../../shared/AppHeader";
import Close from "../../shared/Close";
import Loader from "../../shared/Loader";
import SongForm from "./SongForm";
import "./EditSong.scss";

const EditSong = () => {
  const context = useContext(Context);
  const songId = context.editSong;
  const song = context.songs.find((s) => s._id === songId);

  const { isLoading, sendRequest, setIsLoading } = useHttpClient();
  const [formState, setFormState] = useState({
    songName: song.name,
    artist: song.artist,
    producer: song.producer,
    label: song.label,
    type: "slow",
  });
  const [songImg, setSongImg] = useState(song.imgURL);
  const [published, setPublished] = useState(song.published);

  const [songImgPath, setSongImgPath] = useState(song.imgPath);
  const [audioPath, setAudioPath] = useState(song.songPath);
  const [vocalsPath, setVocalsPath] = useState(song.vocalsPath);
  const [kickPath, setKickPath] = useState(song.kickPath);
  const [snarePath, setSnarePath] = useState(song.snarePath);
  const [bassPath, setBassPath] = useState(song.bassPath);
  const [samplesPath, setSamplesPath] = useState(song.samplesPath);
  const [fxPath, setFxPath] = useState(song.fxPath);
  const [originalId, setOriginalId] = useState(song.backupOf);
  const [backupType, setBackupType] = useState(song.backupType || "");
  const [AIEnabled, setAIEnabled] = useState(song.AIEnabled);
  const [firebaseId, setFirebaseId] = useState(song.firebaseId);
  const [songLink, setSongLink] = useState(song.songURL);

  const submitHandler = async (file, audioFile) => {
    setIsLoading(true);

    if (file && audioFile) {
      const aPath = `Audio/${firebaseId}/main.${audioFile.name
        .split(".")
        .pop()}`;

      const path = `Pictures/SongsPics/${firebaseId}.${file.name
        .split(".")
        .pop()}`;

      if (songImgPath) firebaseDelete(songImgPath);
      if (audioPath) firebaseDelete(audioPath);

      const [getUploadTask, getDownloadURL] = firebaseUpload(aPath, audioFile);

      const uploadTask = getUploadTask();
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {},
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const [getUploadImgTask, getDownloadImgURL] = firebaseUpload(
              path,
              file
            );

            const uploadImgTask = getUploadImgTask();
            uploadImgTask.on(
              "state_changed",
              (snapshot) => {},
              (error) => {},
              () => {
                getDownloadImgURL(uploadImgTask.snapshot.ref).then((imgURL) => {
                  setSongImg(imgURL);
                  setSongLink(downloadURL);
                  setAudioPath(aPath);
                  setSongImgPath(path);

                  saveSongHandler();
                });
              }
            );
          });
        }
      );
    } else if (file) {
      const path = `Pictures/SongsPics/${firebaseId}.${file.name
        .split(".")
        .pop()}`;

      if (songImgPath) firebaseDelete(songImgPath);

      const [getUploadImgTask, getDownloadImgURL] = firebaseUpload(path, file);

      const uploadImgTask = getUploadImgTask();
      uploadImgTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {},
        () => {
          getDownloadImgURL(uploadImgTask.snapshot.ref).then((imgURL) => {
            setSongImg(imgURL);
            setSongImgPath(path);

            saveSongHandler();
          });
        }
      );
    } else if (audioFile) {
      const aPath = `Audio/${firebaseId}/main.${audioFile.name
        .split(".")
        .pop()}`;

      if (audioPath) firebaseDelete(audioPath);

      const [getUploadTask, getDownloadURL] = firebaseUpload(aPath, audioFile);

      const uploadTask = getUploadTask();
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {},
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setSongLink(downloadURL);
            setAudioPath(aPath);

            saveSongHandler();
          });
        }
      );
    } else {
      saveSongHandler();
    }
  };

  const saveSongHandler = async () => {
    const response = await sendRequest(
      `${process.env.REACT_APP_API_URL}/songs/${songId}`,
      "POST",
      JSON.stringify({
        name: formState.songName,
        songURL: songLink,
        artist: formState.artist,
        producer: formState.producer,
        label: formState.label,
        style: formState.type,
        imgURL: songImg,
        imgPath: songImgPath,
        songPath: audioPath,
        originalSongId: originalId,
        published,
        backupType,
      }),
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    if (response) {
      if (response.logout) {
        context.logout();
        return;
      }

      context.setSongs((songs) => {
        const index = songs.findIndex((s) => s._id === context.editSong);
        songs[index] = response.song;
        return songs;
      });
    }
  };

  const deleteSongHandler = async () => {
    if (songImgPath) firebaseDelete(songImgPath);
    if (audioPath) firebaseDelete(audioPath);
    if (vocalsPath) firebaseDelete(vocalsPath);
    if (kickPath) firebaseDelete(kickPath);
    if (snarePath) firebaseDelete(snarePath);
    if (bassPath) firebaseDelete(bassPath);
    if (samplesPath) firebaseDelete(samplesPath);
    if (fxPath) firebaseDelete(fxPath);

    await sendRequest(
      `${process.env.REACT_APP_API_URL}/songs/`,
      "DELETE",
      JSON.stringify({
        songId,
      }),
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    context.setSongs((songs) => songs.filter((s) => s._id !== songId));
    context.setTab("songs_settings");
  };

  useEffect(() => {
    if (published !== song.published) {
      saveSongHandler();
    }
  }, [published]);

  return (
    <ModalContainer>
      <Notification
        text={`CREDITS [${
          context.userType === "admin" || context.userType === "artist"
            ? "unlimited"
            : context.credits
        }]`}
      />
      <AppHeader text="Edit Song" />
      <Close onClick={() => context.setTab("songs_settings")} />

      <div className="edit_song">
        <SongForm
          isLoading={isLoading}
          formState={formState}
          setFormState={setFormState}
          submitHandler={submitHandler}
          originalId={originalId || ""}
          setOriginalId={setOriginalId}
          songId={songId}
          AIEnabled={AIEnabled}
          savedSongImg={songImg}
          backupType={backupType}
          setBackupType={setBackupType}
        />
        <div className="song_form__buttons">
          <button
            className="song_form__status"
            onClick={() => {
              setPublished((p) => !p);
            }}
          >
            {published ? "Make private" : "Make public"}
          </button>

          <button className="song_form__delete" onClick={deleteSongHandler}>
            Delete song
          </button>
        </div>
        {isLoading && <Loader />}
      </div>
    </ModalContainer>
  );
};

export default EditSong;
