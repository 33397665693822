import { useContext, useState, useEffect } from "react";
import { Context } from "../../utils/context";
import { useHttpClient } from "../../utils/http-hook";
import ModalContainer from "../shared/ModalContainer";
import Notification from "../shared/Notification";
import AppHeader from "../shared/AppHeader";
import Close from "../shared/Close";
import Input from "../shared/Input";
import Loader from "../shared/Loader";
import UserTile from "./components/UserTile";
import "./Users.scss";

const Users = () => {
  const context = useContext(Context);
  const { sendRequest, isLoading, setIsLoading } = useHttpClient();

  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  // Fetch users when search term changes or on component mount
  useEffect(() => {
    setPage(1);
    setMaxPage(1);
    setUsers([]);
  }, [search]);

  useEffect(() => {
    const fetchUsers = async () => {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/`,
        "POST",
        JSON.stringify({ search, page }),
        {
          Authorization: `Bearer ${context.token}`,
          "Content-Type": "application/json",
        }
      );

      if (responseData) {
        if (responseData.logout) {
          context.logout();
          return;
        }
        setMaxPage(responseData.maxPage);
        setUsers((users) => [
          ...users,
          ...responseData.users.filter((u) => u.id !== context.userId),
        ]);
      }
    };

    fetchUsers();
  }, [page, search]);

  // Handlers for admin and verification changes
  const updateUserStatus = async (id, isVerified, userType) => {
    const endpoint =
      userType !== undefined ? "/update-user-type" : "/verification";
    const propertyToUpdate =
      userType !== undefined
        ? { userType: userType }
        : { verified: isVerified };

    setUsers((currentUsers) =>
      currentUsers.map((user) =>
        user._id === id ? { ...user, ...propertyToUpdate } : user
      )
    );

    await sendRequest(
      `${process.env.REACT_APP_API_URL}/users${endpoint}`,
      "POST",
      JSON.stringify({
        userId: id,
        ...propertyToUpdate,
      }),
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      }
    );
  };

  const handleUserTypeChange = (id, userType) =>
    updateUserStatus(id, undefined, userType);

  const handleVerificationChange = (id, verified) =>
    updateUserStatus(id, verified);

  const deleteUser = async (id) => {
    setUsers((currentUsers) => currentUsers.filter((user) => user.id !== id));
    await sendRequest(
      `${process.env.REACT_APP_API_URL}/users/delete-user`,
      "DELETE",
      JSON.stringify({
        userId: id,
      }),
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      }
    );
  };

  const updateCredits = async (id, credits) => {
    setUsers((currentUsers) =>
      currentUsers.map((user) =>
        user.id === id ? { ...user, aiCredits: credits } : user
      )
    );

    await sendRequest(
      `${process.env.REACT_APP_API_URL}/users/update-credits`,
      "POST",
      JSON.stringify({
        userId: id,
        aiCredits: credits,
      }),
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      }
    );
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleSearch = (e) => {
    let search = e.target.value;

    setTimeout(() => {
      if (search !== e.target.value) return;
      setSearch(search);
    }, 300);
  };

  return (
    <ModalContainer onScroll={handleScroll}>
      <Notification
        text={`CREDITS [${
          context.userType === "admin" || context.userType === "artist"
            ? "unlimited"
            : context.credits
        }]`}
      />
      <AppHeader text="Users" />
      <Close onClick={() => context.setTab(null)} />

      <div className="users">
        <Input
          placeholder="Search"
          // value={search}
          type="text"
          onInput={(e) => handleSearch(e)}
        />

        <div className="users__grid">
          {users &&
            users.map((u, i) => (
              <UserTile
                key={u._id}
                {...u}
                id={u._id}
                index={users.length - i}
                onUserTypeChange={handleUserTypeChange}
                onVerificationChange={handleVerificationChange}
                deleteUser={deleteUser}
                updateCredits={updateCredits}
              />
            ))}
        </div>
        {isLoading && <Loader />}
      </div>
    </ModalContainer>
  );
};

export default Users;
