import React, { useEffect, useRef, useState, useContext } from "react";
import { Context } from "../../../utils/context";
import "./GeneratingLoader.scss";

const initialCode = [
  { text: "ACCESSING THE SONG DATABASE", id: 1 },
  { text: 'ACCESS CODE: "HAPPY MONDAY"...', id: 2 },
  { text: "SONG LIBRARY: FOUND...", id: 3 },
  { text: "DOWNLOADING MP3 FILE: COMPLETE...", id: 4 },
  { text: "DOWNLOADING STEMS: VOCALS.WAV...", id: 5 },
  { text: "DOWNLOADING STEMS: MELODY.WAV...", id: 6 },
  { text: "DOWNLOADING STEMS: DRUMS.WAV...", id: 7 },
  { text: "DOWNLOADING STEMS: 808S.WAV...", id: 8 },
  { text: "DOWNLOADING STEMS: FX.WAV...", id: 9 },
  { text: "DOWNLOADING STEMS: SAMPLE.WAV...", id: 10 },
  { text: "ANALYZING FILE: VOCALS.WAV...", id: 11 },
  { text: "ANALYZING FILE: MELODY.WAV...", id: 12 },
  { text: "ANALYZING FILE: DRUMS.WAV...", id: 13 },
  { text: "ANALYZING FILE: 808S.WAV...", id: 14 },
  { text: "ANALYZING FILE: FX.WAV...", id: 15 },
  { text: "ANALYZING FILE: SAMPLE.WAV...", id: 16 },
  { text: "GENERATING NEW STEMS: MPFIVE GENERATOR...", id: 17 },
  { text: "CONVERTING MP3 TO MP5...", id: 18 },
];

const GeneratingLoader = () => {
  const context = useContext(Context);
  const [visibleLines, setVisibleLines] = useState([]);
  const [status, setStatus] = useState("INITIALIZING...");
  const animationRef = useRef();
  const startTimeRef = useRef();

  const animate = (timestamp) => {
    if (!startTimeRef.current) {
      startTimeRef.current = timestamp;
    }

    const elapsed = timestamp - startTimeRef.current;
    const duration = 180000; // 3 minutes total animation
    const newProgress = Math.min((elapsed / duration) * 100, 100);

    // Update visible code lines based on progress
    const lineCount = Math.floor((newProgress / 100) * initialCode.length);
    setVisibleLines(initialCode.slice(0, lineCount).map((line) => line.id));

    if (newProgress < 100 && !context.generationFinished) {
      animationRef.current = requestAnimationFrame(animate);
    }
  };

  useEffect(() => {
    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Update status messages based on progress
    if (context.progress < 25) {
      setStatus("UPLOADING HARD DRIVE TO THE CLOUD");
    } else if (context.progress < 50) {
      setStatus("ANALYZING AUDIO FILES");
    } else if (context.progress < 75) {
      setStatus("CONVERTING MP3 TO MP5...");
    } else if (context.progress < 100) {
      setStatus("UPDATING TRAINING MODEL");
    } else {
      setStatus("GENERATION COMPLETE: YOUR MPFIVE IS READYツ");
    }
  }, [context.progress]);

  return (
    <div className="hard-drive-access">
      <div id="title">ACCESSING THE HARD DRIVE [EST. WAIT TIME 3 MIN]</div>
      <div id="console" className="console">
        {initialCode.map((line, index) => (
          <div
            key={line.id}
            className="code-line"
            style={{
              animationDelay: `${index * 0.5}s`,
              opacity: visibleLines.includes(line.id) ? 1 : 0,
            }}
          >
            <span className="arrow">{">"}</span> {line.text}
          </div>
        ))}
      </div>
      <div className="status">{status}</div>
    </div>
  );
};

export default GeneratingLoader;
