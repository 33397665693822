import { useContext, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useHttpClient } from "../../../utils/http-hook";
import { Context } from "../../../utils/context";
import { firebaseUpload } from "../../../utils/upload-hook";
import ImgModal from "./ImgModal";
import SongModal from "./SongModal";
import YoutubeModal from "./YoutubeModal";
import TikTokModal from "./TikTokModal";
import Button from "../../../assets/play-pause.png";
import Img from "../../../assets/img-icon.svg";
import Song from "../../../assets/song-icon.svg";
import Youtube from "../../../assets/yt-icon.svg";
import TikTok from "../../../assets/tik-tok-icon.svg";
import ImgActive from "../../../assets/img-icon-active.svg";
import SongActive from "../../../assets/song-icon-active.svg";
import YoutubeActive from "../../../assets/yt-icon-active.svg";
import TikTokActive from "../../../assets/tik-tok-icon-active.svg";
import "./PostForm.scss";
import Loader from "../../shared/Loader";

const PostForm = () => {
  const context = useContext(Context);
  const { sendRequest, setIsLoading, isLoading } = useHttpClient();

  const [file, setFile] = useState();
  const [text, setText] = useState("");
  const [img, setImg] = useState();
  const [song, setSong] = useState();
  const [youtube, setYoutube] = useState();
  const [tiktok, setTiktok] = useState();

  const [openModal, setOpenModal] = useState("");
  const closeModal = () => {
    setOpenModal("");
  };

  const submitHandler = async (e) => {
    if (isLoading) return;
    e.preventDefault();
    setIsLoading(true);

    if (file) {
      const path =
        "Pictures/Posts/" + uuidv4() + "." + file.name.split(".").slice(-1);
      const [getUploadTask, getDownloadURL] = firebaseUpload(path, file);
      const uploadTask = getUploadTask();

      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {},
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            savePostHandler(downloadURL, path);
          });
        }
      );
    } else {
      savePostHandler(null, null);
    }
  };

  const savePostHandler = async (imgURL, imgPath) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_API_URL}/community`,
      "POST",
      JSON.stringify({
        text: text,
        creator: context.userId,
        imgURL,
        imgPath,
        songId: song,
        youtube: youtube,
        tiktok: tiktok,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.token,
      }
    );

    if (responseData) {
      if (responseData.logout) {
        context.logout();
        return;
      }
      setText("");
      setImg(null);
      setSong(null);
      setYoutube(null);
      setTiktok(null);
      setFile(null);
      context.setPosts((posts) => [responseData, ...posts]);
    }
  };

  return (
    <>
      <form className="post_form" onSubmit={submitHandler}>
        <div className="post_form__text">
          {!isLoading && (
            <textarea
              placeholder="SHARE SOMETHING"
              value={text}
              onInput={(e) => setText(e.target.value)}
            ></textarea>
          )}

          {!isLoading && (
            <div className="post_form__icons">
              <img
                onClick={() => setOpenModal("img")}
                src={img ? ImgActive : Img}
                alt="Upload a picture"
              />
              <img
                onClick={() => setOpenModal("song")}
                src={song ? SongActive : Song}
                alt="Choose a song"
              />
              <img
                onClick={() => setOpenModal("youtube")}
                src={youtube ? YoutubeActive : Youtube}
                alt="Add youtube"
              />
              <img
                onClick={() => setOpenModal("tiktok")}
                src={tiktok ? TikTokActive : TikTok}
                alt="Add tiktok"
              />
            </div>
          )}

          {isLoading && <Loader />}
        </div>

        <div className="post_form__publish">
          <button>
            <img src={Button} alt="Publish" />
          </button>
          <p>POST</p>
        </div>
      </form>

      {openModal === "img" && (
        <ImgModal
          img={img}
          setImg={setImg}
          closeModal={closeModal}
          setFile={setFile}
        />
      )}

      {openModal === "song" && (
        <SongModal song={song} setSong={setSong} closeModal={closeModal} />
      )}

      {openModal === "youtube" && (
        <YoutubeModal
          youtube={youtube}
          setYoutube={setYoutube}
          closeModal={closeModal}
        />
      )}

      {openModal === "tiktok" && (
        <TikTokModal
          tiktok={tiktok}
          setTikTok={setTiktok}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default PostForm;
