import { useContext, useState, useEffect } from "react";
import { Context } from "../../../utils/context";
import { useHttpClient } from "../../../utils/http-hook";
import Input from "../../shared/Input";
import GoogleSignIn from "./GoogleSignIn";
import Button from "../../shared/Button";
import Loader from "../../shared/Loader";

import "./AuthForm.scss";

const AuthForm = () => {
  const context = useContext(Context);
  const isLogin = window.location.pathname === "/login";

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { isLoading, error, sendRequest, clearError, setError } =
    useHttpClient();

  //google sign-in
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userId = params.get("userId");
    const email = params.get("email");
    const userType = params.get("userType");
    const token = params.get("token");
    const theme = params.get("theme");
    const credits = params.get("credits");
    const errorMsg = params.get("error");

    if (errorMsg) {
      context.setError(decodeURIComponent(errorMsg));
    } else if (userId && token) {
      context.login(userId, userType, token, email, credits, theme);
    }
  }, []);

  const googleAuth = async () => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_API_URL}/google`,
      "POST",
      null,
      {
        "Content-Type": "application/json",
      }
    );

    if (responseData) {
      if (responseData.logout) {
        context.logout();
        return;
      }
      window.location.href = responseData.url;
    }
  };

  // input handlers
  const usernameInputHandler = (e) => {
    setUsername(e.target.value);
    context.setError("");
  };

  const emailInputHandler = (e) => {
    setEmail(e.target.value);
    context.setError("");
  };

  const passwordInputHandler = (e) => {
    setPassword(e.target.value);
    context.setError("");
  };

  //submit auth
  const submitHandler = async (e) => {
    e.preventDefault();

    if (isLogin) {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/login`,
        "POST",
        JSON.stringify({
          email,
          password,
        }),
        {
          "Content-Type": "application/json",
        }
      );

      if (responseData) {
        context.login(
          responseData.userId,
          responseData.userType,
          responseData.token,
          email,
          responseData.credits,
          responseData.theme
        );
      }
    } else {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/signup`,
        "POST",
        JSON.stringify({
          name: username,
          email,
          password,
        }),
        {
          "Content-Type": "application/json",
        }
      );

      if (responseData) {
        context.login(
          responseData.userId,
          responseData.userType,
          responseData.token,
          email,
          responseData.credits,
          responseData.theme
        );
      }
    }
  };

  return (
    <form className="auth__form" onSubmit={submitHandler}>
      {!isLogin && (
        <Input
          placeholder="Username"
          value={username}
          type="text"
          onInput={usernameInputHandler}
          required
        />
      )}
      <Input
        placeholder="Email"
        value={email}
        type="email"
        onInput={emailInputHandler}
        required
      />
      <Input
        placeholder="Password"
        value={password}
        type="password"
        onInput={passwordInputHandler}
        required
      />
      <p>
        BY CLICKING SIGN UP, YOU AGREE TO OUR{" "}
        <a
          href="https://www.mp5player.net/terms"
          target="_blank"
          rel="noreferrer"
        >
          TERMS AND CONDITIONS OF USE
        </a>
      </p>
      <GoogleSignIn onClick={googleAuth} />
      <Button text={isLogin ? "Log in" : "Sign up"} />
      {isLoading && <Loader />}
    </form>
  );
};

export default AuthForm;
