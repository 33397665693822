import { createContext } from "react";

export const Context = createContext({
  error: "",
  email: "",
  credits: 0,
  userId: null,
  userType: null,
  songs: null,
  favorites: null,
  setFavorites: null,
  token: null,
  activeSong: null,
  tab: null,
  currentTime: 0,
  duration: 0,
  comments: null,
  postComments: null,
  activePost: null,
  posts: null,
  editSong: null,
  theme: null,
  customMoods: null,
  customGenre: null,
  customTempo: null,
  customEnergyLevels: null,
  customMuteStems: null,
  audioRef: null,
  aiLoading: false,
  activeAISong: null,
  activeRemix: null,
  mode: null,
  user: {},
  generationFinished: false,
  progress: 0,
  setProgress: () => {},
  setGenerationFinished: () => {},
  setUser: () => {},
  setCurrentTime: () => {},
  setDuration: () => {},
  setTab: () => {},
  setActiveSong: () => {},
  setError: () => {},
  login: () => {},
  logout: () => {},
  setComments: () => {},
  setPostComments: () => {},
  setActivePost: () => {},
  setPosts: () => {},
  setEditSong: () => {},
  setSongs: () => {},
  setTheme: () => {},
  setAudioRef: () => {},
  generateSong: () => {},
  setActiveAISong: () => {},
  setActiveRemix: () => {},
  setAiLoading: () => {},
  setMode: () => {},
  setCustomMoods: () => {},
  setCustomGenre: () => {},
  setCustomTempo: () => {},
  setCustomEnergyLevels: () => {},
  setCustomMuteStems: () => {},
  spedUp: () => {},
  slowDown: () => {},
});
