import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import ModalContainer from "../shared/ModalContainer";
import Notification from "../shared/Notification";
import { useHttpClient } from "../../utils/http-hook";
import AppHeader from "../shared/AppHeader";
import Preview from "../../assets/placeholder-tile.jpg";
import Loader from "../shared/Loader";
import CancelSubscriptionModal from "./components/CancelSubscriptionModal";
import "./Subscribe.scss";

const Subscriptions = () => {
  const navigate = useNavigate();
  const context = useContext(Context);
  const { sendRequest, isLoading } = useHttpClient();
  const [subscriptions, setSubscriptions] = useState([]);
  const [confirmCancelId, setConfirmCancelId] = useState();

  useEffect(() => {
    const fetchSubscriptions = async () => {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/subscriptions/`,
        "GET",
        null,
        {
          Authorization: "Bearer " + context.token,
          "Content-Type": "application/json",
        }
      );

      if (responseData) {
        if (responseData.logout) {
          context.logout();
          return;
        }

        if (responseData.subscriptions.length === 0) {
          navigate("/");
          return;
        }

        setSubscriptions(
          responseData.subscriptions.filter(
            (s) => new Date(s.activeUntil) > new Date()
          )
        );
      }
    };

    if (context.token) fetchSubscriptions();
  }, [context.token]);

  return (
    <ModalContainer>
      <Notification text="Manage your subscriptions" />
      <div className="subscribe">
        <div className="subscribe_body">
          <AppHeader text="Active Subscriptions" />
          {subscriptions.map((s) => (
            <div className="subscribe_tile subscribe_tile--no_hover">
              <img src={Preview} alt="" />
              <div>
                <h3>{s.subscriptionType.name}</h3>
                <p>${s.subscriptionType.usdPrice} PER MONTH</p>
                <p>
                  Active until {new Date(s.activeUntil).toLocaleDateString('en-US')}
                </p>
                {s.cancelled && (
                  <p className="subscribe_tile__cancelled">Cancelled</p>
                )}
                {!s.cancelled && (
                  <p
                    className="subscribe_tile__cancel"
                    onClick={() => setConfirmCancelId(s.subscriptionType._id)}
                  >
                    Cancel Subscription
                  </p>
                )}
              </div>
            </div>
          ))}

          {isLoading && <Loader />}

          <div className="subscribe_body__buttons">
            <button
              onClick={() => {
                context.setTab(null);
                navigate("/subscribe?nr=t");
              }}
              className="link"
            >
              Subcribe To More Artists
            </button>
            <button
              onClick={() => {
                context.setTab(null);
              }}
              className="link"
            >
              Go Back
            </button>
          </div>
        </div>
      </div>

      {confirmCancelId && (
        <CancelSubscriptionModal
          id={confirmCancelId}
          closeModal={() => setConfirmCancelId()}
          setSubscriptions={setSubscriptions}
        />
      )}
    </ModalContainer>
  );
};

export default Subscriptions;
