import Placeholder from "../../../assets/placeholder-tile.jpg";
import Select from "../../shared/Select";
import "./UserTile.scss";

const UserTile = (props) => {
  const {
    id,
    userType,
    email,
    verified,
    img,
    name,
    onUserTypeChange,
    onVerificationChange,
    aiCredits,
    deleteUser,
    updateCredits,
  } = props;

  // Handle admin status toggle
  const changeUserTypeHandler = (value) => {
    onUserTypeChange(id, value);
  };

  // Handle verification status toggle
  const clickVerifyHandler = () => {
    onVerificationChange(id, !verified);
  };

  const deleteHandler = () => {
    deleteUser(id);
  };

  return (
    <div className="user">
      <div className="user__header">
        <div className="user__header_left">
          <img
            className="user__header_img"
            src={img || Placeholder}
            alt={`${name}'s profile`}
          />

          <div>
            <h3>{name}</h3>
            <span className="user__header_email">{email}</span>
          </div>
        </div>

        <Select
          data={[
            { name: "Admin", value: "admin" },
            { name: "User", value: "user" },
            { name: "Artist", value: "artist" },
          ]}
          default={userType}
          onChange={(value) => changeUserTypeHandler(value)}
        />
      </div>

      <div className="user__body">
        <div className="user__body_credits">
          <label htmlFor="credits">Credits</label>
          {userType === "user" && (
            <input
              placeholder={aiCredits}
              type="number"
              id="credits"
              min="0"
              onChange={(e) => {
                setTimeout(() => updateCredits(id, e.target.value), 300);
              }}
            />
          )}

          {userType !== "user" && (
            <input placeholder="Unlimited" type="text" id="credits" disabled />
          )}
        </div>

        <div className="user__buttons">
          <button onClick={clickVerifyHandler}>
            {verified ? "Remove Verification" : "Verify"}
          </button>
          <button className="delete" onClick={deleteHandler}>
            Delete User
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserTile;
